import { min } from 'lib/array/arrayUtils'
import {
  AccommodationItem,
  SavedHotelData,
} from 'tripPlanner/types/tripItem'

export const getMatchingRoomRateIdForBookmarkedData = (
  offerId: string,
  packages: Array<App.Package>,
  savedHotelData: SavedHotelData,
): Array<string> => {
  if (savedHotelData.offerId !== offerId) return []

  const { roomTypeId, durationInDays } = savedHotelData
  const roomRateIds: Array<string> = []
  let filteredPackages: Array<App.Package> = packages
  if (roomTypeId) {
    if (durationInDays && durationInDays > 0) {
      // hotel + room + duration
      filteredPackages = packages.filter(
        ({ duration, roomType, roomRate }) =>
          durationInDays === duration &&
          roomType?.id === roomTypeId &&
          roomRate?.id,
      )
    } else {
      // hotel + room
      filteredPackages = packages.filter(
        ({ roomType, roomRate }) => roomType?.id === roomTypeId && roomRate?.id,
      )
    }
  } else if (durationInDays && durationInDays > 0) {
    // hotel + duration without room selection
    filteredPackages = packages.filter(
      ({ duration, roomRate }) => durationInDays === duration && roomRate?.id,
    )
  }

  filteredPackages.forEach(
    (p) => p.roomRate && roomRateIds.push(p.roomRate?.id),
  )
  return roomRateIds
}

export const isBookmarkedHotelOfferAvailable = (
  offerId: string,
  packages: Array<App.Package>,
  savedHotelData: SavedHotelData,
  rates: Array<App.OfferAvailableRate>,
) => {
  const roomRateIds = getMatchingRoomRateIdForBookmarkedData(
    offerId,
    packages,
    savedHotelData,
  )
  const roomRateIdsSet = new Set(roomRateIds)

  return rates.some((rate) => roomRateIdsSet.has(rate.roomRateId))
}

export const findLowestHotelPackageByDuration = (
  packages: Array<App.Package>,
  duration: number,
) => {
  if (duration > 0) {
    const pkgs = packages.filter(
      (p) => p.duration === duration && p.price > 0,
      null,
    )
    return min(pkgs, (p) => p.price + (p?.propertyFees ?? 0))
  }
  return undefined
}

export const findHotelPackage = (
  packages: Array<App.Package> | undefined,
  item: AccommodationItem,
) => {
  if (!packages) return undefined

  const { startDay, endDay } = item
  let duration = -1
  if (startDay && endDay) {
    duration = endDay - startDay
  } else if (item.savedItemData?.durationInDays) {
    duration = item.savedItemData.durationInDays
  }

  if (duration > 0) {
    const pkg = packages.find(
      (p) => p.id === item?.savedItemData?.packageId && duration === p.duration,
    )

    if (pkg) return pkg

    return findLowestHotelPackageByDuration(packages, duration)
  }
  return undefined
}
