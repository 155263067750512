import {
  experiencePageUrlWithPrefilledState,
  getCruiseV2OfferUrl,
  offerPageUrlWithPrefilledState,
} from '..'

import { DEFAULT_ADULT_OCCUPANTS } from 'constants/flight'
import {
  buildStandaloneFlightSearchUrl as buildStandaloneFlightSearchUrlFromQueryParams,
  isOneWayFlight,
} from 'lib/flights/flightUtils'
import offerPageURL, { getExperienceOfferPageURL } from 'lib/offer/offerPageURL'
import { addQueryParamsToPath } from 'lib/url/searchUrlUtils'
import { OfferTypeForTripItemTypes } from 'tripPlanner/hooks/api'
import {
  AccommodationItem,
  FlightItem,
  LETripItem,
  SavedAccommodationData,
  SavedCruiseData,
  SavedExperienceData,
  SavedFlightSearchData,
  SavedHotelData,
  SavedTourData,
  TourItem,
} from 'tripPlanner/types/tripItem'

export function flightHasBundledHotel(
  data: SavedFlightSearchData,
): data is Utils.RequiredProp<SavedFlightSearchData, 'hotelBundle'> {
  return !!data.hotelBundle
}

export function flightHasBundledTour(
  data: SavedFlightSearchData,
): data is Utils.RequiredProp<SavedFlightSearchData, 'tourBundle'> {
  return !!data.tourBundle
}

export function hasBundledFlight<Data extends SavedHotelData | SavedTourData>(
  data: Data,
): data is Utils.RequiredProp<Data, 'flightBundle'> {
  return !!data.flightBundle
}

export function buildStandaloneFlightSearchURL(
  savedItemData: SavedFlightSearchData,
) {
  const {
    originAirportCode,
    originAirportName,
    destinationAirportCode,
    destinationAirportName,
    departDate,
    returnDate,
    fareClass,
    fareType,
    travellerCounts,
  } = savedItemData

  const isOneWayFare = isOneWayFlight(fareType)

  return buildStandaloneFlightSearchUrlFromQueryParams({
    originAirportCode,
    originAirportName,
    destinationAirportCode,
    destinationAirportName,
    departDate,
    returnDate: (!isOneWayFare && returnDate) || null,
    adults: travellerCounts?.adults || DEFAULT_ADULT_OCCUPANTS,
    children: travellerCounts?.children ?? 0,
    infants: travellerCounts?.infants ?? 0,
    childrenAge: travellerCounts?.childrenAge?.join(',') ?? '',
    fareClass: fareClass.toLowerCase(),
    fareType,
  })
}

export const isBookableHotel = ({
  checkIn,
  checkOut,
  roomTypeId,
  rateId,
  packageId,
  occupancies,
  offerType,
}: // regionCode and currencyCode are omitted here because the `hotelBundle` field on flight savedItemData doesn't have them
Omit<SavedHotelData, 'regionCode' | 'currencyCode'>) => {
  if (offerType === 'bedbank_hotel') {
    return (
      !!checkIn &&
      !!checkOut &&
      !!rateId &&
      !!(packageId || roomTypeId) &&
      !!occupancies &&
      occupancies.length > 0
    )
  } else {
    return (
      !!checkIn &&
      !!checkOut &&
      !!roomTypeId &&
      !!packageId &&
      !!rateId &&
      !!occupancies &&
      occupancies.length > 0
    )
  }
}

export const isBookableTour = ({
  startDate,
  endDate,
  packageId,
  occupancies,
}: // regionCode and currencyCode are omitted here because the `tourBundle` field on flight savedItemData doesn't have them
Omit<SavedTourData, 'regionCode' | 'currencyCode'>): boolean => {
  return (
    !!startDate &&
    !!endDate &&
    !!packageId &&
    !!occupancies &&
    occupancies.length > 0
  )
}

export const isBookableExperience = ({
  tickets,
}: SavedExperienceData): boolean => {
  return !!tickets && tickets.length > 0
}

export const isBookableCruise = (data: SavedCruiseData): boolean => {
  if (data.offerType === 'cruise') {
    const {
      offerId,
      cabinCategory,
      cabinCategoryDetails,
      occupancy,
      departure,
    } = data

    return (
      !!offerId &&
      !!cabinCategory &&
      !!cabinCategoryDetails &&
      !!occupancy &&
      !!departure
    )
  } else if (data.offerType === 'tour') {
    return isBookableTour(data)
  }

  throw new Error('Tour V2s are not bookable as cruise')
}

type HotelWithFlightBundle = AccommodationItem & {
  savedItemData: Utils.RequiredProp<
    SavedAccommodationData,
    'flightBundle'
  >
}
type FlightWithHotelBundle = FlightItem & {
  savedItemData: Utils.RequiredProp<SavedFlightSearchData, 'hotelBundle'>
}
type TourWithFlightBundle = TourItem & {
  savedItemData: Utils.RequiredProp<SavedTourData, 'flightBundle'>
}
type FlightWithTourBundle = FlightItem & {
  savedItemData: Utils.RequiredProp<SavedFlightSearchData, 'tourBundle'>
}

export const isBookableHotelFlightBundle = (
  item: LETripItem,
): item is HotelWithFlightBundle | FlightWithHotelBundle => {
  return (
    (item.type === 'ACCOMMODATION' &&
      hasBundledFlight(item.savedItemData) &&
      isBookableHotel(item.savedItemData)) ||
    (item.type === 'FLIGHT' &&
      flightHasBundledHotel(item.savedItemData) &&
      isBookableHotel(item.savedItemData.hotelBundle))
  )
}

export const isBookableTourV1FlightBundle = (
  item: LETripItem,
): item is TourWithFlightBundle | FlightWithTourBundle => {
  return (
    (item.type === 'TOUR' &&
      hasBundledFlight(item.savedItemData) &&
      isBookableTour(item.savedItemData) &&
      item.savedItemData.offerType === 'tour') ||
    (item.type === 'FLIGHT' &&
      flightHasBundledTour(item.savedItemData) &&
      isBookableTour(item.savedItemData.tourBundle) &&
      item.savedItemData.tourBundle.offerType === 'tour')
  )
}

export const isBookableItem = (item: LETripItem) => {
  switch (item.type) {
    case 'CRUISE': {
      return isBookableCruise(item.savedItemData)
    }
    case 'EXPERIENCE': {
      return isBookableExperience(item.savedItemData)
    }
    case 'FLIGHT': {
      return false
    }
    case 'TOUR': {
      return isBookableTour(item.savedItemData)
    }
    case 'ACCOMMODATION': {
      return isBookableHotel(item.savedItemData)
    }
  }
}

export const buildProductLinkWithQueryParams = (
  item: LETripItem,
  withPrefilledState: boolean = true,
  {
    offer,
    experience,
  }: {
    offer?: OfferTypeForTripItemTypes
    experience?: App.ExperienceOffer
  } = {},
  params: { [key: string]: any },
): string | undefined => {
  const url = buildProductLink(item, withPrefilledState, { offer, experience })
  return url && addQueryParamsToPath(url, params)
}

export const buildProductLink = (
  item: LETripItem,
  withPrefilledState: boolean = true,
  {
    offer,
    experience,
  }: {
    offer?: OfferTypeForTripItemTypes
    experience?: App.ExperienceOffer
  } = {},
): string | undefined => {
  switch (item.type) {
    case 'CRUISE':
      if (item.savedItemData.offerType === 'cruise') {
        return getCruiseV2OfferUrl(item)
      }
      if (item.savedItemData.offerType === 'tour') {
        if (offer) {
          return withPrefilledState ?
            offerPageUrlWithPrefilledState(offer, item) :
            offerPageURL(offer)
        }
      }
      break
    case 'EXPERIENCE':
      if (experience) {
        return withPrefilledState ?
          experiencePageUrlWithPrefilledState(
            experience,
            item.savedItemData,
            false,
          ) :
          getExperienceOfferPageURL(experience)
      }
      break
    case 'FLIGHT':
      return buildStandaloneFlightSearchURL(item.savedItemData)
    case 'TOUR':
    case 'ACCOMMODATION':
      if (offer) {
        return withPrefilledState ?
          offerPageUrlWithPrefilledState(offer, item) :
          offerPageURL(offer)
      }
  }
}
